import '@babel/polyfill'
import EventBus from './event-bus'
import AOS from 'aos'
import { debounce } from 'lodash-es'
import lazysizes from 'lazysizes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight, faChevronDown, faPlus, faSearch, faEnvelope, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { faCircle, faTimes } from '@fortawesome/pro-light-svg-icons'
import { faFacebookF, faTwitter, faLinkedinIn, faTwitterSquare  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import stickybits from 'stickybits'
import freeform from './freeform'
import objectFitImages from 'object-fit-images'
import Pjax from 'pjax'

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.css');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    library.add(faTimes, faChevronLeft, faChevronRight, faPlus, faFacebookF, faTwitter, faLinkedinIn, faEnvelope, faSearch, faCircle, faChevronDown, faSpinner)
    Vue.default.component('font-awesome-icon', FontAwesomeIcon)
    Vue.default.component('font-awesome-layers', FontAwesomeLayers)

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
            'mainNav': () => import(/* webpackChunkName: "nav" */ '../vue/MainNav.vue'),
            'sidebarNav': () => import(/* webpackChunkName: "sidebar-nav" */ '../vue/SidebarNav.vue'),
            'flickity': () => import(/* webpackChunkName: "flickity" */ '../vue/Flickity.vue'),
            'countUp': () => import(/* webpackChunkName: "count-up" */ '../vue/CountUp.vue'),
            'loadingIndicator': () => import(/* webpackChunkName: "loading-indicator" */ '../vue/LoadingIndicator.vue'),
            'pjaxLink': () => import(/* webpackChunkName: "pjax-link" */ '../vue/PjaxLink.vue'),
            'pjaxPagination': () => import(/* webpackChunkName: "pjax-pagination" */ '../vue/PjaxPagination.vue'),
        },
        data: {
            isMobile: true,
            mainNavIsOpen: false,
            mailingListIsOpen: false,
            processingPjaxRequest: false,
            paginationInfo: {},
        },
        methods: {
            toggleMainNav() {
                if (this.mainNavIsOpen) {
                    this.mainNavIsOpen = false
                }
                else {
                    this.mainNavIsOpen = true
                }
            },
            closeMailingList() {
                this.mailingListIsOpen = false
            },
            checkIfMobile: debounce(function() {
                var matchMedia = window.matchMedia("(max-width: 767px)")
                if (matchMedia.matches) {
                    this.isMobile = true
                } else {
                    this.isMobile = false
                }
            }),
            getPaginationInfo() {
                let element = this.$el.querySelector('.js-content')
                if (element) {
                    this.paginationInfo = JSON.parse(element.dataset.paginationInfo)
                }
            },
            flickityInit(event) {
                if (event.element || null) {
                    var imageWrapper = event.element.querySelectorAll('.js-banner-image-wrapper.opacity-0')
                    for(var index=0;index<imageWrapper.length;index++) {
                        imageWrapper[index].classList.remove('opacity-0')
                    }
                }
            }
        },
        created() {
            this.checkIfMobile()
            window.addEventListener("resize", this.checkIfMobile)

            EventBus.$on('mainNav.close', () => {
                this.mainNavIsOpen = false
            })
        },
        mounted() {
            AOS.init({
                duration: 1800,
                once: true
            });

            setTimeout(() => {
                this.mailingListIsOpen = true
            }, 7200);

            stickybits('.js-sticky', {
                useStickyClasses: true,
            });

            freeform()

            objectFitImages()

            var pjax = new Pjax({
              elements: ".js-pjax", // default is "a[href], form[action]"
              selectors: ["title", ".js-content"],
              scrollTo: false,
            })

            document.addEventListener('pjax:send', () => {
                this.processingPjaxRequest = true
            })

            document.addEventListener('pjax:complete', () => {
                this.processingPjaxRequest = false
                this.getPaginationInfo()
            })

            this.$root.$on('pjax.refresh', () => {
                pjax.refresh()
            })

            this.$root.$on('paginationInfo.update', (payload) => {
                this.paginationInfo.currentEntry = payload
            })

            this.getPaginationInfo()
        },
        beforeDestroy () {
            window.removeEventListener("resize", this.checkIfMobile)
        }
    });
};

const initMain = () => {
    // Execute async function
    main().then((value) => {
    })
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initMain); // Document still loading so DomContentLoaded can still fire :)
} else {
    initMain()
}
